section#additional-content {
    padding: 5rem 0;
    h3 {
        width: 40%;
        margin: 0 auto;
        text-align: center;
    }

    .content-grid {
        display: flex;

        .content-item {
            margin-bottom: 5rem;
            width: 31%;
            &:not(:nth-of-type(3n)) {
                margin-right: 3.5%;
            }
            h4 {
                font-family: $oswald;
                color: $light-blue;
                text-transform: uppercase;
                font-weight: normal;
                font-size: 1.5rem;
                border-bottom: 3px solid $light-blue;
                margin-bottom: 1.25rem;
                padding-bottom: 1rem;
            }

            h5 {
                margin: 0;
                font-size: 1.3125rem;
                font-family: $oswald;
                font-weight: 500;
                color: $medium-gray;
            }
            .message {
                p {
                    font-family: $merriweather;
                    color: $medium-gray;
                    font-size: .875rem;
                    line-height: 1.75;
                }
            }
            .link-container {
                .view-link {
                    background-color: $light-charcoal;
                    color: $white;
                    font-family: $oswald;
                    text-transform: uppercase;
                    text-decoration: none;
                    font-size: 14px;
                    padding: .3rem 1rem;
                    border-radius: 5px;
                    display: inline-flex;
                    align-items: center;

                    i {
                        font-size: 1.75rem;
                        padding: 0 0 0 1rem;
                        svg{
                            width: 23px;
                            height: auto;
                        }
                    }

                    &:hover {
                        background-color: $dark;
                    }

                }
            }

        }
    }

    @include breakpoint(tablet, max) {
        h3 {
            width: 80%;
        }
    }

    @include breakpoint(mobile, max) {
        .content-grid {
            flex-wrap: wrap;
            .content-item {
                width: 100%;
                &:not(:nth-of-type(3n)) {
                    margin: 0;
                }

                h4 {
                    font-size: 1.125rem;
                    margin-bottom: .5rem;
                    padding-bottom: .5rem;
                }

                h5 {
                    font-size: 1.125rem;
                }
                
                .message {
                    p {
                        font-size: .8125rem;
                    }
                }
                .link-container {
                    width: 50%;
                    .view-link {
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}