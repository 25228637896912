#youth-2023.main {
    display: flex;
    padding: 2rem 0;
    gap: 3rem;
    .side-bar {
        $sbwidth: 325px;
        min-width: $sbwidth;
        max-width: $sbwidth;

        .sidebar-menu {
            font-family: $oswald;
            .sidenav-link.active {
                color: $black;
                pointer-events: none;
            }

            .sidenav-link {
                display: inline-flex;
                align-items: center;
                i {
                    margin-left: .5rem;
                    font-size: .65em;
                }
            }

            .parents {
                list-style-type: none;
                padding: 0;
                margin: 2rem 0;

                & > li {
                    font-size: 1.25rem;
                }

                .children {
                    list-style-type: none;

                    & > li {
                        font-size: 1.25rem;
                        font-weight: 100;
                    }
                }
            }
        }

        .button-menu {
            a {
                display: block;
                background-color: $light-blue;
                color: $white;
                padding: 1rem;
                font-family: $libre;
                font-size: 1.25rem;
                margin: 1rem 0;
                box-sizing: border-box;

                i {
                    margin-right: .5rem;
                    svg{
                        width: 16px;
                    }

                    &.external-icon {
                        margin-left: .5rem;
                    }
                }

                &:hover {
                    background-color: $hover-blue;
                }
            }
        }

        .social-menu {
            display: flex;
            gap: 1rem;
            a {
                i {
                    font-size: 2rem;
                    svg{
                        width: 35px;

                        &:hover {
                            fill: $hover-blue;
                        }
                    }
                }
            }
        }

        .sponsors {
            .sponsor {
                display: block;
                margin: 1.5rem 0;
                position: relative;
                img {
                    display: block;
                }

                .title {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-family: $oswald;
                    font-size: 1.75rem;
                    color: $yellow;
                    width: 90%;
                    text-align: center;
                    opacity: 0;
                }

                &:hover {
                    background-color: $black;
                    img {
                        opacity: .5;
                    }
                    .title {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .content {
        h1 {
            font-size: 3rem;
            font-family: $merriweather;
            margin: 0 0 1rem;
        }
        .featured-resources {
            gap: 1rem;
            padding-top: 0;

            .featured-resource {
                width: calc((100% - 1rem) / 2);
                flex-basis: auto;

                @media screen and (max-width: 650px) {
                    width: 100%;
                }
            }

            &#whats-new {
                flex-wrap: wrap;
                .featured-resource:first-child {
                    flex-basis: 100%;
                }
            }
        }

        h2 {
            font-family: $oswald;
            font-weight: normal;
        }

        .page-body {
            margin: 2rem 0;
            font-family: $merriweather;
            line-height: 2.25;
            font-size: 17px;
            color: $light-charcoal;

            p, ul, ol, a {
                font-family: $merriweather;
                line-height: 2.25;
                font-size: 17px;
            }
            p, ul, ol {
                color: $light-charcoal;
            }

            h2 {
                color: $dark-gray;
                font-family: $oswald;
                font-weight: normal;
                font-size: 30px;
            }

            h3 {
                font-family: $oswald;
                font-weight: normal;
                font-size: 18px;
                color: $yellow;
                text-transform: uppercase;
            }

            blockquote {
                max-width: 640px;
                margin: 2rem auto;
                border-left: 3px solid $charcoal;
                padding-left: 1.5rem;
                color: $blockquote;
                font-size: 18px;
                font-family: $oswald;
                line-height: 1.625rem;
            }

            pre {
                overflow-x: auto;
                white-space: pre-wrap;
                white-space: -moz-pre-wrap;
                white-space: -pre-wrap;
                white-space: -o-pre-wrap;
                word-wrap: break-word;
            }
            figure {
                figcaption {
                    font-family: Libre Franklin, sans-serif;;
                    color: #6b6b6b;
                    font-size: .8125rem;
                }
            }
        }
    }

    @include breakpoint(tablet, max) {
        flex-direction: column;
        .side-bar {
            $sbmwidth: 100%;
            min-width: $sbmwidth;
            max-width: $sbmwidth;
            order: 2;

            background-color: $light-charcoal;
            padding: 5%;
            box-sizing: border-box;
            border-top: 20px solid $red;

            .sidebar-menu {
                .sidenav-link.active {
                    color: $red;
                }
    
                .sidenav-link {
                    color: $white;
                }
    
                .parents {
                    & > li {
                        font-size: 1.25rem;
                    }
    
                    .children {
                        padding-left: 1rem;
                    }
                }
            }

            .button-menu {
                a {
                    background-color: $red;
                    font-size: .85rem;
    
                    &:hover {
                        background-color: $transDark-red;
                    }
                }
            }

            .social-menu {
                display: flex;
                gap: 1rem;
                a {
                    color: $white;

                    i {
                        font-size: 2rem;
                    }

                    &:hover {
                        color: $transDark-red;
                    }
                }
            }
        }

        .content {
            h1 {
                font-size: 24px;
            }
        }
    }
}

#youth-banner {
    background-color: $red;

    .banner-inner {
        display: flex;
        color: $white;
        gap: 2rem;
        align-items: center;
        padding: .5rem 0;

        .main-text {
            font-family: $oswald;
            font-size: 1.5rem;
            text-transform: uppercase;
        }

        .add-text {
            font-family: $oswald;
            font-size: .875rem;
        }

        .image {
            width: 50px;
            border-radius: 100%;
            overflow: hidden;
            transition: transform 1s ease;
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }

    &:hover {
        background-color: $dark-red;

        .banner-inner {
            .image {
                transform: rotate(360deg);
            }
        }
    }
}