section#resources-list {
    padding-bottom: 3rem;
    .resource {
        display: flex;
        align-items: center;
        border-top: 1px solid $light-gray;
        padding: 10px 0;
        min-height: 104px;

        &:last-of-type {
            border-bottom: 1px solid $light-gray;
        }

        .resource-img {
            margin-right: 1.5rem;
            min-width: 156px;
            max-width: 156px;
        }

        .resource-title {
            a {
                display: flex;
                align-items: center;
                font-size: 21px;
                font-weight: 500;
                font-family: $oswald;
                padding: .5rem 2rem .5rem 0;
                color: $medium-gray;
                
                &:hover {
                    color: $dark-gray;
                }
            }
            
            .trimmed {
                display: none;
            }
        }

        .category-button {
            margin-left: auto;
            a {
                white-space: nowrap;
                text-transform: uppercase;
                font-weight: normal;
                font-size: 14px;
                padding: .35rem 1.5rem .35rem .75rem;

                i {
                    position: relative;
                    top: 2px;
                    padding-right: .5rem;
                    svg{
                        width: 16px;
                    }
                }
            }
        }
    }
    .button-wrapper {
        display: flex;
        justify-content: center;
        margin: 3rem 0;
    }

    @include breakpoint(tablet, max) {
        .resource {
            .resource-img {
                min-width: 18%;
                max-width: 18%;
            }
        }
    }

    @include breakpoint(mobile, max) {
        .resource {
            min-height: 94px;
            .resource-title {
                font-size: 18px;

                .full {
                    display: none;
                }
                .trimmed {
                    display: block;
                }
            }

            .category-button {
                a {
                    white-space: normal;
                    text-align: center;
                    padding: .75rem;
                    border-radius: 0;
                    font-size: 10px;

                    i {
                        display: block;
                        margin: 0 auto;
                        padding: 0;
                        font-size: 36px;
                    }

                }
            }
        }
    }
}