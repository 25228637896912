form {
    .hidden {
        display: none !important;
    }
    small {
        font-family: $libre;
        font-size: 13px;
    }
    .message {
        font-family: $merriweather;
        padding: 2rem 0;
        .required {
            color: $red;
        }
    }
    .selectric-wrapper {
        .selectric {
            background-color: transparent;
            color: $medium-gray;
            border-color: $hover-gray;
            .label {
                color: $medium-gray;
                text-transform: none;
                margin-left: 2.5%;
            }
            .button {
                background-color: transparent;
                color: transparent;

                &:after {
                    border-top-color: $medium-gray;
                }
            }
        }
        .selectric-items {
            .selectric-scroll {
                ul {
                    li {
                        background-color: transparent;
                        color: $medium-gray;
                        border-color: $light-gray;

                        &.selected.highlighted {
                            background-color: $extra-light-gray;
                        }
                    }
                }
            }
        }
    }
    label {
        font-family: $oswald;
        font-weight: 300 !important;
        display:block;
		line-height:normal;
		color:$darker-gray;
		margin:0 0 1px;
		font-size: 15px;
		@include breakpoint(mobile, min){
			font-size: 17px;
			margin:0 0 5px;
		}

        &.top {
            font-family: $merriweather;
            font-weight: 700 !important;
            padding: .5rem 0;
        }
    }
    input[type=text], input[type=email], textarea, .text {
		display:block;
		padding:14px 13px 12px;
		font-family:$libre;
		font-weight:400;
		line-height:normal;
		color:$darker-gray;
		border:1px solid #D8D8D8;
		border-radius:0;
		background:$white;
		width:100%;
		font-size: 16px;
		@include breakpoint(mobile, min){
			font-size: 18px;
		}
		&:hover {
			border-color:$dark-red;
		}
		&:active {
			border-color:$light-blue;
		}
		&:focus {
			border-color:$dark-red;
		}
	}
	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active,
	select:-webkit-autofill,
	select:-webkit-autofill:hover,
	select:-webkit-autofill:focus,
	select:-webkit-autofill:active {
		transition:background-color 5000s ease-in-out 0s;
		-webkit-text-fill-color:$darker-gray !important;
	}
	.checkbox-group, .radio-group {
		margin:0 0 25px;
		@include breakpoint(mobile, min){
			margin:0 0 35px;
		}
	}
	.pretty {
		display:block;
		white-space:inherit;
		.state label:before,
		.state label:after {
            top:4px !important;
        }
        &.p-default input:checked~.state label:after {
            background-color: $darker-gray !important;
        }
		.state label:before {
			background:$white;
			border-color:none;
        }
		label {
			font-family:$oswald;
			font-weight:400;
            font-size: 16px;
            line-height: 27px;
			color:$darker-gray;
			&:last-of-type {
				margin:0;
            }
		}
	}
	.button {
		margin:0 0 25px;
		@include breakpoint(mobile, min){
			margin:0 0 35px;
		}
	}
	.required {
		color:$red;
	}
    input, button, textarea {
        display: block;
        outline: 0;
    }
    input, textarea {
        font-family: $oswald;
        width: 95%;
        font-size: .95rem;
        padding: .5rem 2.5%;
        border: 1px solid $hover-gray;

        &::placeholder {
            font-size: .95rem;
            color: $hover-gray;
        }
    }

    textarea {
        min-height: 200px;
        max-width: 95%;
        min-width: 95%;
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
    }
    #mc_embed_shell{
        border: 1px solid red
    }
    &.subscribe-form {
        input, button {
            display: block;
            outline: 0;
            border: 0;
            // margin: 1.5rem 0;
        }
        input {
            font-family: $oswald;
            width: 80%;
            font-size: .95rem;
            padding: .5rem .5rem;
            border-radius: 5px;
    
            &::placeholder {
                font-size: .95rem;
                color: $medium-gray;
            }
        }
        .input-group {
            color: $text-gray;
            .age-check{
                display: flex;
                align-items: center;
                input{
                    width: 10%;
                }
            }
        }
    }
    .form-note {
        font-size: 1.25rem;
    }
    
    .form-instructions {
        font-family: $libre;
        margin: .25em 0;
    }
    
    .form-success {
        color: $white;
    }
    
    .form-error {
        color: $red;
    }
    
    .field-error {
        font-family: $libre;
        color: $red;
    }      
    
}

.freeform-pages {
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    margin: 0 0 10px;
    list-style: none;
}
.freeform-pages li {
    margin: 0 10px 0 0;
}
.freeform-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 -15px;
}
.freeform-row .freeform-column {
    -ms-flex: 1 0;
    flex: 1 0;
    padding: 10px 0;
    margin: 0 15px;
    box-sizing: border-box;
    position: relative;
}
.freeform-row .freeform-column>.freeform-row:first-child {
    margin-top: -10px;
}
.freeform-row .freeform-column label {
    display: block;
}
.freeform-row .freeform-column .input-group-one-line {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.freeform-row .freeform-column .input-group-one-line label {
    padding-right: 10px;
}
.freeform-row .freeform-column .freeform-label {
    font-weight: bold;
}
.freeform-row .freeform-column .freeform-label.freeform-required:after {
    content: "*";
    margin-left: 5px;
    color: red;
}
.freeform-row .freeform-column .freeform-input {
    width: 100%;
    display: block;
    box-sizing: border-box;
}
.freeform-row .freeform-column .freeform-input[type=checkbox],
.freeform-row .freeform-column .freeform-input[type=radio] {
    width: auto;
    display: inline;
    margin-right: 5px;
}
.freeform-row .freeform-column .freeform-input.StripeElement {
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
    appearance: textfield;
    border: 1px solid;
    padding-top: 5px;
    height: 26px;
}
.freeform-row .freeform-column .freeform-input-only-label {
    font-weight: normal;
}
.freeform-row .freeform-column .freeform-input-only-label>.freeform-input {
    display: inline-block;
    width: auto;
    margin-right: 5px;
}
.freeform-row .freeform-column .freeform-errors,
.freeform-row .freeform-column .ff-errors {
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
}
.freeform-row .freeform-column .freeform-errors>li,
.freeform-row .freeform-column .ff-errors>li {
    color: red;
}
.freeform-row .freeform-column .freeform-instructions {
    margin: 0 0 5px;
    font-size: 13px;
    color: #ABA7A7;
}
.freeform-row .freeform-column.freeform-column-content-align-left {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.freeform-row .freeform-column.freeform-column-content-align-left>button:not(:first-of-type) {
    margin-left: 5px;
}
.freeform-row .freeform-column.freeform-column-content-align-center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
}
.freeform-row .freeform-column.freeform-column-content-align-center>button:not(:first-of-type) {
    margin-left: 5px;
}
.freeform-row .freeform-column.freeform-column-content-align-right {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.freeform-row .freeform-column.freeform-column-content-align-right>button:not(:first-of-type) {
    margin-left: 5px;
}
.freeform-row .freeform-column.freeform-column-content-align-spread {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.freeform-row .freeform-column.freeform-column-content-align-spread>button:not(:first-of-type) {
    margin-left: 5px;
}
.freeform-form-has-errors,
.ff-form-errors {
    color: red;
}

.freeform-row .freeform-column .asterisk {
	color: red;
}


// MC Override Styling
#mc-embedded-subscribe-form{
}

#mc_embed_signup form{
    margin: 0;
}

#mc_embed_signup_scroll{
    color: $text-gray;
    // h2{
    //     color: $yellow;
    // }
    label{
        color: $text-gray;
    }
    strong{
        color: $yellow;
    }

    .email-confirm{
        color: $yellow;
    }

    .content__gdpr{
        .mc_fieldset{
            padding: 0;
            input{
                margin-right: 6px;
            }
        }
    }

    .checkbox.subfield{
        display: flex;
        input{
            width: 13px;
        }
    }

    // .clear{
        #mc-embedded-subscribe{
            margin: 0;
        }
    //     display: flex;
    //     justify-content: center;
    // }

    .button-sq-rd{
        border: none;
        font-family: $oswald;
        font-weight: 600;
        font-size: 1.125rem;
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        padding: .75rem 3rem;
        border-radius: 5px;
        background-color: $red;
        color: $white;
        &:hover {
            background-color: $button-red-hover;
        }
    }
}
#mc_embed_signup #mce-success-response{
    color: white;
}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error{
    background-color: transparent;
}

#mc_embed_signup div.mce_inline_error{
    background-color: transparent;
}