div#lead-nav {
    background-color: $medium-charcoal;

    .lead-nav-inner, .lead-nav-inner-mobile {
        display: flex;
        justify-content: flex-start;

        .lead-item {
            color: $white;
            font-family: $oswald;
            text-decoration: none;
            font-weight: 300;
            font-size: 1.125rem;
            margin: .65rem  2.5rem .65rem 0;
            white-space: nowrap;
            display: inline-flex;
            align-items: center;

            &:hover, &.active {
                color: $dark-red;
            }

            i {
                font-size: .5em;
                margin-left: .25rem;
                svg{
                    width: 16px;
                }
            }
        }
    }

    .lead-toggle {
        display: none;
    }

    @include breakpoint(tablet, min) {
        .lead-nav-inner-mobile {
            display: none !important;
        }
    }

    @include breakpoint(tablet, max) {
        position: relative;
        .lead-nav-inner{
            display: none;
        }
        .lead-nav-inner-mobile {
            display: none;
            position: absolute;
            width: 100%;
            top: 41px;
            background-color: $medium-charcoal;
            z-index: 20;
            text-align: right;

            .lead-item {
                display: flex;
                justify-content: flex-end;
                padding-right: 1rem;
                margin: 0;
                padding: .5rem 5% .5rem .5rem;

                &:nth-of-type(odd) {
                    background-color: $dark-charcoal;
                }
            }
        }

        .lead-toggle {
            display: block;
            width: 10px;
            cursor: pointer;
            margin: 0 5% 0 auto;
            font-size: 1.125rem;
            padding: .65rem 0;
            color: $white;
            transition: all .25s ease;


            &:hover {
                color: $hover-gray;
            }

            &.turn {
                transform: rotate(-90deg);
            }
        }
    }
}