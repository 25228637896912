#leadgen-modal {
	position:fixed;
	top:100px;
	right:0;
	bottom:0;
	left:0;
	z-index:99999;
	overflow:auto;
	display:none;

	&.contact {
		top: 0;

		.form-wrap {
			max-width: 1230px;
			max-height: 100vh;
			padding: 4em;
			overflow-y: auto;
		}
	}

	.overlay {
		background:rgba(#000,0.65);
		position:fixed;
		top:0;
		right:0;
		bottom:0;
		left:0;
	}
	.form-wrap {
		max-width:640px;
		padding:20px;
		margin:0 auto;
		position:relative;
		z-index:99999;
		background:$white;
		@include breakpoint(desktop, min){
			padding:50px;
		}
	}
	.modal-form {
		position:relative;
		.toggle-form-modal {
			display:block;
			width:30px;
			height:30px;
			position:absolute;
			top:-30px;
			right:-30px;
			&:focus {
				text-decoration:none;
			}
			span {
				display:block;
				width:30px;
				margin:0;
				font-size:30px;
				color:$navy-blue;
				transition: all .2s ease;
				&:hover {
					color:$dark-red;
				}
			}
		}
		.button {
			margin:0;
		}
	}
}
