section#page-banner {
    &-full, &-half {
        .page-title {
            padding: 2.5rem 0;
            font-family: $merriweather;
            color: $white;
            flex-basis: 100%;
            align-self: start;
            h1 {
                font-size: 3.625rem; 
                font-weight: 600;
                margin: 0;
            }

            .sub-title {
                font-size: 1.125rem;
                margin: 0;
                width: 40%;
            }

            .page-banner-embed-wrapper {
                width: 100%;
                padding: 1rem 0 0;
                iframe {
                    aspect-ratio: 16 / 9;
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                }
            }

            @include breakpoint(1350px, max) {
                max-width: 90%;
            }

            @include breakpoint(mobile, max) {
                h1 {
                    font-size: 48px;
                }
                .sub-title {
                    font-size: 16px;
                    width: 100%;
                }
                margin-bottom: 2rem;
            }
        }
    }
    &-full {
        .overlay-wrapper {
            &.mask-1 {
                background-image: url('/assets/images/overlay.png');
            }
            &.mask-2 {
                background-image: url('/assets/images/overlay2.png');
            }
            display: flex;
            justify-content: space-between;
        }
        &.right {
            .overlay-wrapper {
                height: 100%;
                .page-title {
                    width: 40%;
                    flex-basis: 40%;
                    margin-left: 10%;

                    .sub-title {
                        width: 100%;
                    }
                }
                .col {
                    width: 50%;
                    margin: auto 0 2.5rem;

                    &.small {
                        width: 40%;
                    }
                }
                .text-wrapper {
                    border-radius: 10px 0 0 10px;
                    margin-top: 108px;
                    img {
                        right: 108px;
                    }

                    .text-inner {
                        padding: 1em 3em 1em 3em;
                    }
                }
                .button {
                    left: auto;
                    right: 95%;
                }
            }
        }

        &.left {
            .overlay-wrapper {
                height: 100%;
                .page-title {
                    width: 40%;
                    flex-basis: 40%;
                    margin-right: 10%;

                    .sub-title {
                        width: 100%;
                    }
                }
                .col {
                    width: 50%;
                    margin: auto 0 2.5rem;
                    order: -1;

                    &.small {
                        width: 40%;
                    }
                }
                .text-wrapper {
                    border-radius: 0 10px 10px 0;
                    margin-top: 108px;
                    img {
                        left: 108px;
                    }

                    .text-inner {
                        padding: 1em 3em 1em 3em;
                    }
                }
                .button {
                    left: 95%;
                    right: auto;
                }
            }
        }
        min-height: 645px;
        height: auto;
        background-size: cover;
        background-position: center;

        &.shorten {
            min-height: 483px;
            height: auto;

            .overlay-wrapper {
                min-height: 483px;
                height: auto;
            }
        }
        .col.small {
            .text-wrapper {
                align-self: center;
                .text-inner {
                    font-size: 1.5rem;
                    font-weight: 600;
                    line-height: 31px;
                    padding-bottom: 2rem;
                }
            }
        }
        .overlay-wrapper {
            min-height: 645px;
            height: auto;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            
            &.darken {
                background-color: rgba(0,0,0,.5);
            }
            .text-wrapper {
                background-color: $transBlack;
                position: relative;

                img {
                    position: absolute;
                    border-radius: 100%;
                    width: 108px;
                    height: 108px;
                    top: -54px;
                }
                .text-inner {
                    color: $white;
                    font-family: $merriweather;
                    font-size: 2.25rem;
                    font-weight: 700;

                    &.w-img {
                        padding-top: 75px;
                    }
                }
                .button {
                    position: absolute;
                    display: block;
                    white-space: nowrap;
                    top: 40%;
                }
            }
        }

        @include breakpoint(tablet, max) {
            &.left, &.right {
                &.shorten {
                    height: auto;
                    min-height: auto;

                    .overlay-wrapper {
                        height: auto;
                        min-height: auto;
                    }
                }
                .overlay-wrapper {
                    height: 100%;
                    flex-direction: column;
                    .page-title {
                        width: 90%;
                        margin: 0 auto;
                    }
                    .col {
                        width: 100%;
                        margin: auto 0 0;
                        &.small {
                            width: 100%;
                            .text-wrapper {
                                margin: 0 5% 10px 5%;
                                .text-inner {
                                    font-size: 1.125rem;
                                } 
                            }
                        }
                    }
                    .text-wrapper {
                        margin: 0 5% 10px 5%;
                        border-radius: 5px;

                        .flex {
                            display: flex;
                            padding-top: 20px;
                            img {
                                position: relative;
                                top: 0;
                                left: 0;
                                height: 180px;
                                width: auto;
                            }
                            .text-inner.w-img {
                                padding-top: 0;
                            }
                        }

                        img {
                            width: 87px;
                            height: 87px;
                            top: -43.5px;
                            left: auto;
                            right: 15px;
                        }
                        .text-inner {
                            font-size: 1.75rem;
                            padding: 1rem;

                            &.w-img {
                                padding-top: 50px;
                            }
                        }

                        .button {
                            position: static;
                            text-align: center;
                            padding: 1rem 0;
                        }
                    }
                }
            }
        }

        @include breakpoint(mobile, max) {
            min-height: 367px;
            height: auto;
            .overlay-wrapper {
                min-height: 367px;
                height: auto;
            }
            &.shorten {
                height:auto;
            }
            &.left, &.right {
                .overlay-wrapper {
                    .text-wrapper {
                        .text-inner {
                            font-size: 1.125rem;
                        }
                    }
                }
            }
        }
    }

    &-half {
        background-size: cover;
        background-position: center;
        height: 225px;
        margin-bottom: 2rem;
        .overlay-wrapper {
            height: 100%;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            &.mask-1 {
                background-image: url('/assets/images/overlay.png');
            }
            &.mask-2 {
                background-image: url('/assets/images/overlay2.png');
            }
            &.darken {
                background-color: rgba(0,0,0,.75);
            }

            .page-title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                padding: 0;
                
                
            }
        }

        @include breakpoint(mobile, max) {
            height: auto;

            .overlay-wrapper {
                padding: 2rem 0;
            }
        }
    }
}