.sponsor-heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 2rem;

    .button {
        margin: .5rem 0 1rem;
        white-space: nowrap;
    }

    @include breakpoint(tablet, max) {
        flex-wrap: wrap;
        align-items: flex-start;
        
        .button {
            font-size: .875rem;
            margin: 0 0 1rem;
        }
    }
}

.sponsor-level {
    background-color: $ultra-light-gray;
    padding: .5rem 1rem;
}
.sponsor-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 4rem;

    .sponsor {
        width: calc((100% - 4rem) / 3);
        background-color: $ultra-light-gray;
        border-radius: 5px;
        display: flex;
        flex-direction: column;

        .details {
            padding: 1rem;

            .name {
                color: $medium-gray;
                font-size: 1.25rem;
                margin: 0 0 1rem;
                font-family: $oswald;
            }

            .summary {
                color: $medium-gray;
                font-family: $libre;
                font-size: .825rem;
            }
        }

        .read {
            margin-top: auto;
            background-color: $red;
            border-radius: 0 0 5px 5px;
            text-align: center;
            padding: 1rem;
            font-family: $oswald;
            color: $white;
        }

        &:hover {
            background-color: $extra-light-gray;

            .logo {
                background-color: $black;
                img {
                    opacity: .75;
                }
            }

            .read {
                background-color: $dark-red;
            }
        }
    }

    @include breakpoint(mobile, max) {
        .sponsor {
            width: calc((100% - 2rem) / 2);
        }
    }

    @include breakpoint(xs, max) {
        .sponsor {
            width: 100%;
        }
    }
}