section.generic-content {
    border-top: 1px solid $gray;
    .content-wrap {
        margin: 2rem auto 6rem;

        .content-header {
            display: flex;
            width: 83%;
    
            .date-box {
                font-family: $oswald;
                color: $light-charcoal;
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                padding: 0 1.5rem .5rem 1.5rem;
                align-self: flex-start;
                flex-grow: 1;
    
                .numeral {
                    font-weight: bold;
                    font-size: 4.5rem;
                    line-height: 1;
                }
                .month {
                    font-size: 1.125rem;
                    text-align: center;
                }
            }
            .page-heading {
                min-width: 79%;
                max-width: 79%;
                margin-left: auto;
                margin-bottom: 2rem;

                h3.feature {
                    margin: 0;
                    padding-right: 1rem;
                }
                .author-tag {
                    text-transform: uppercase;
                    color: $light-gray;
                    font-family: $oswald;
                    font-size: 18px;
                }
            }
        }
        
        .content-inner {
            width: 83%;

            .page-main {
                flex-basis: 100%;
                margin-left: 21%;

                .event-info {
                    ul {
                        list-style-type: none;
                        padding: 0;
                        margin: 2rem 0 0;

                        li {
                            display: flex;
                            align-items: center;
                            margin: .75rem 0;
                            font-family: $oswald;
                            i {
                                color: $red;
                                font-size: 1.25em;
                                padding-right: .5em;
                                width: 20px;
                            }
                        }
                    }
                }

                .podcast-container {
                    margin-top: 2rem;
                }

                .page-body {
                    margin: 2rem 0;
                    font-family: $merriweather;
                    line-height: 2.25;
                    font-size: 17px;
                    color: $light-charcoal;

                    p, ul, ol, a {
                        font-family: $merriweather;
                        line-height: 2.25;
                        font-size: 17px;
                    }
                    p, ul, ol {
                        color: $light-charcoal;
                    }

                    h2 {
                        color: $dark-gray;
                        font-family: $oswald;
                        font-weight: normal;
                        font-size: 30px;
                    }

                    h3 {
                        font-family: $oswald;
                        font-weight: normal;
                        font-size: 18px;
                        color: $yellow;
                        text-transform: uppercase;
                    }

                    blockquote {
                        max-width: 640px;
                        margin: 2rem auto;
                        border-left: 3px solid $charcoal;
                        padding-left: 1.5rem;
                        color: $blockquote;
                        font-size: 18px;
                        font-family: $oswald;
                        line-height: 1.625rem;
                    }

                    pre {
                        overflow-x: auto;
                        white-space: pre-wrap;
                        white-space: -moz-pre-wrap;
                        white-space: -pre-wrap;
                        white-space: -o-pre-wrap;
                        word-wrap: break-word;
                    }
                    figure {
                        figcaption {
                            font-family: Libre Franklin, sans-serif;;
                            color: #6b6b6b;
                            font-size: .8125rem;
                        }
                    }
                }

                .tags {
                    border-top: solid $gray;
                    border-width: 2px 0;
                    margin: 2rem 0 0;

                    a.tag {
                        background-color: $tags;
                        border-radius: 25px;
                        display: inline-block;
                        color: $white;
                        font-family: $libre;
                        font-size: 13px;
                        padding: .25rem 1rem;
                        margin: 1.5rem 1rem 0 0;
                        white-space: nowrap;
                        
                        &:last-of-type {
                            margin-bottom: 1.5rem;
                        }

                        &:hover {
                            background-color: $light-gray;
                        }
                    }
                }
                
                .author-info {
                    border-top: solid $gray;
                    border-width: 2px 0;
                    display: flex;
                    align-items: center;
                    padding: 1rem 0;

                    .headshot {
                        width: 108px;
                        border-radius: 100%;
                    }

                    .author-bio {
                        font-size: 15px;
                        font-family: $libre;
                        color: $light-charcoal;
                        line-height: 2;
                        padding: 0 0 0 2rem;
                    }
                }
            }
        }
    }
    @include breakpoint(desktop, max) {
        .content-wrap {
            .content-inner {
                .page-main {
                    .page-body {
                        figure {
                            width: 40% !important;
                            max-width: 40% !important;
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(tablet, max) {
        .content-wrap {
            .content-header, .content-inner {
                width: 100%;
            }
        }
    }

    @include breakpoint(mobile, max) {
        .content-wrap {
            .content-header {
                .date-box {
                    .numeral {
                        font-size: 28px;
                    }
                    .month {
                        font-size: 13px;
                    }
                }
                .page-heading {
                    h3.feature {
                        font-size: 24px;
                    }
                    .author-tag {
                        font-size: 14px;
                    }

                    &.no-date {
                        width: 100%;
                        max-width: 100%;
                    }
                }
            }

            .content-inner {
                .page-main {
                    margin-left: 0;

                    .page-body {
                        p, ul, ol, a {
                            font-size: 15px;
                        }
                        h2 {
                            font-size: 20px;
                        }
                        h3 {
                            font-size: 16px;
                        }
                        blockquote>p {
                            font-size: 16px;
                        }
                        figure {
                            float: none !important;
                            width: 100% !important;
                            max-width: 100% !important;
                            margin:10px 0 !important;

                            img {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .author-info {
                        flex-wrap: wrap;
                        justify-content: center;
                        .author-bio {
                            width: 100%;
                            padding: 1rem 0;
                        }
                    }
                }
            }
        }
    }
}