section.podcast-show {
    border-bottom: 1px solid $light-gray;
    .show-info {
        display: flex;
        padding: 2rem 0;

        .show-thumb {
            min-width: 14.2%;
            max-width: 14.2%;
            
            img {
                outline: .25px solid $extra-light-gray;
            }
        }
        .show-detail {
            min-width: 31.4%;
            max-width: 31.4%;
            padding: 0 3%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            h3 {
                font-family: $oswald;
                font-weight: 200;
                font-size: 36px;
                margin: 0 0 .5rem;
                line-height: 1;
                color: $light-charcoal;
            }

            .show-desc {
                font-family: $libre;
                font-size: 15px;
                color: $light-charcoal;
                line-height: 2;
            }
        }
        .show-embed {
            flex-grow: 1;
        }
    }

    .show-list {
        h3.list {
            font-family: $oswald;
            color: $light-blue;
            text-transform: uppercase;
            font-weight: normal;
            font-size: 1.5rem;
            border-bottom: 3px solid $light-blue;
            margin-bottom: 0;
            z-index: 5;
            position: relative;
        }
        .content-list {
            padding: 0 0 0 0;
            margin: -2px 0 0 0;
            list-style-type: none;
            z-index: 1;
            position: relative;
            border-bottom: 3px solid $hover-gray;
    
            li {
                border-top: 1px solid $light-gray;
                &:last-of-type {
                    .article-wrap {
                        border: none;
                    }
                }
                .article-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-decoration: none;
                    padding: 2rem 0;
    
                    .article-title {
                        font-family: $oswald;
                        color: $medium-gray;
                        font-size: 1.3125rem;
                        text-decoration: none;
                        padding: 1rem 1rem 1rem 0;
    
                        .trimmed {
                            display: none;
                        }
                    }
                    .article-button {
                        background-color: $red;
                        color: $white;
                        font-family: $oswald;
                        text-transform: uppercase;
                        border-radius: 5px;
                        height: 80px;
                        min-width: 80px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .icon{
                            svg{
                                width: 16px;
                            }
                        }
                        
                        .tag {
                            font-size: .825rem;
                            white-space: nowrap;
                        }
                    }
    
                    &:hover {
                        .article-title {
                            color: $light-charcoal;
                        }
                        .article-button {
                            background-color: $button-red-hover;
                        }
                    }
                }
                
            }
        }
    
        @include breakpoint(tablet, max) {
            .content-list {
                column-count: 1;
                li {
                    .article-wrap {
                        .article-button {
                            border-radius: 0px;
                            height: 10vw;
                            width: 10vw;
                            min-width: 10vw;
                            .icon {
                                font-size: 36px;
                            }
                            .tag {
                                display: none;
                            }
                        }
                    }  
                }
            }
        }
    
        @include breakpoint(mobile, max) {
            h3.list {
                font-size: 1.125rem;
            }
            .content-list {
                li {
                    .article-wrap {
                        padding: .25rem 0;
                        .article-title {
                            .full {
                                display: none;
                            }
                            .trimmed {
                                display: block;
                            }
                        }
                        .article-button {
                            height: 12vw;
                            width: 12vw;
                            min-width: 12vw;
                            .icon {
                                font-size: 20px;
                            }
                        }
                        .article-title {
                            font-size: 1.125rem;
                            padding-right: 3em;
                        }
                    }  
                }
            }
        }
    }
    @include breakpoint(tablet, max) {
        .show-info {
            flex-wrap: wrap;

            .show-detail {
                min-width: 82.8%;
                max-width: 82.8%;
                padding: 0 0 0 3%;
            }
            .show-embed {
                flex-basis: 100%;
                padding-top: 1rem;
            }
        }
    }

    @include breakpoint(mobile, max) {
        .show-info {
            .show-thumb {
                min-width: 100%;
                max-width: 100%;
            }
            .show-detail {
                min-width: 100%;
                max-width: 100%;
                padding: 2rem 0 0;

                h3 {
                    font-size: 24px
                }

                .show-desc {
                    font-size: 13px;
                }
            }
        }
    }
}

section#recommended-podcasts {
    padding-bottom: 5rem;
    .podcast-text {
        width: 45%;
        line-height: 2;
        margin: 3rem auto;
        font-family: $merriweather;
        font-size: 17px;
        color: $hover-gray;
        text-align: center;
    }
    .podcasts {
        display: flex;
        justify-content: space-evenly;
        gap: 4rem;

        .podcast {
            min-width: 48.5%;
            max-width: 48.5%;

            .title {
                font-family: $oswald;
                font-weight: 200;
                font-size: 36px;
                margin: .5rem 0 .5rem;
                color: $light-charcoal;
                line-height: 1.25;
            }

            .desc {
                font-family: $libre;
                font-size: 15px;
                color: $light-charcoal;
                line-height: 2;
            }
        }
    }

    @include breakpoint(tablet, max) {
        .podcast-text {
            width: 75%;
        }

        .podcasts {
            flex-wrap: wrap;
            gap: 0;
            .podcast {
                min-width: 48.5%;
                max-width: 48.5%;
                padding-bottom: 3rem;
            }
        }
    }

    @include breakpoint(mobile, max) {
        .podcast-text {
            width: 100%;
            font-size: 15px;
        }

        .podcasts {
            .podcast {
                min-width: 100%;
                max-width: 100%;
                
                .title {
                    font-size: 24px;
                }

                .desc {
                    font-size: 13px;
                }
            }
        }
    }
}