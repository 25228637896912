section#contact-body {
    margin: 6rem auto;
    form {
        width: 50%;
        margin: 0 auto;
    }
    
    @include breakpoint(tablet, max) {
        form {
            width: 80%;
        }
    }

    @include breakpoint(mobile, max) {
        margin: 3rem auto;

        form {
            width: 100%;
        }
    }
}