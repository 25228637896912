html, body {
    overflow-x: hidden;
}

body.with-nav {
	overflow: hidden;
}

img {
    width: 100%;
    height: auto;
    display: block;
}

a {
    color: $light-blue;
    text-decoration: none;

    &:hover {
        color: $hover-blue;
    }
}

#search-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.75);
    display: none;
    z-index: 100;

    &.open {
        display: block;
    }

    .search-wrap {
        width: 25%;
        margin: 0 auto;
        padding-top: 5rem;

        .search-form {
            position: relative;
            .toggle-search {
                position: absolute;
                top: -5px;
                right: -50px;
                svg{
                    width: 25px;
                }
            }

            div {
                border: none;
               form {
                   td.gsc-input {
                       padding: 0;
                       div.gsc-input-box {
                           padding:0;
                       }
                   }
                   input.gsc-input {
                       height: 50px !important;
                   }
                   button.gsc-search-button {
                       height: 59px;
                       width: 59px;
                       padding: 0;
                       margin: 23px 0 24px 0;
                       background-color: $light-blue;
                       cursor: pointer;

                       &:hover {
                           background-color: $hover-blue;
                       }

                       svg {
                           height: 25px;
                           width: 25px;
                       }
                   }
               } 
            }
        }
    }

    @include breakpoint(tablet, max) {
        .search-wrap {
            width: 75%;
        }
    }

    @include breakpoint(mobile, max) {
        .search-wrap {
            width: 90%;

            .search-form {
                .toggle-search {
                    position: absolute;
                    top: 100px;
                    right: 50%;
                    margin-right: -20px;
                    
                    span::before {
                        font-size: 40px;
                    }
                }
            }
        }
    }
}