section#page-text {
    .text-wrapper {
        width: 66%;
        margin: 0 auto;
        font-family: $merriweather;
        font-size: 17px;
        line-height: 2.25;
        padding: 2rem 0;
    }
    @include breakpoint(tablet, max) {
        .text-wrapper {
            width: 80%;
        }   
    }

    @include breakpoint(mobile, max) {
        .text-wrapper {
            width: 90%;
            font-size: 14px;
        }
    }
}

section.grants-scholarship-feature {
    display: flex;
    justify-content: space-between;

    &.split {
        .item {
            width: 48%;
        }
    }

    .item {
        background-color: $ultra-light-gray;
        border-radius: 0 0 5px 5px;
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;

        h4, .description, .mission-list {
            margin: 1.5rem;
            color: $medium-gray;
        }
        h4 {
            font-family: $oswald;
            font-size: 21px;
            font-weight: 600;
        }
        .description {
            font-family: $merriweather;
            font-size: 12px;
            line-height: 2;
            padding-bottom: 4rem;
        }

        .button {
            width: 100%;
            padding: 1.25rem 0;
            margin-top: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-weight: 400;
            text-transform: uppercase;

            i {
                font-size: 20px;
                padding-left: .5rem;
                svg{
                    width: 16px;
                }
            }
        }
    }

    @include breakpoint(tablet, max) {
        flex-wrap: wrap;

        .item {
            width: 100%;
        }

        &.split {
            .item {
                width: 100%;
            }
        }
    }

    @include breakpoint(mobile, max) {
        .item {
            h4 {
                font-size: 18px;
            }

            .description {
                padding-bottom: 1rem;
            }
        }
    }
}

section#past-list {
    h3.list {
        font-family: $oswald;
        color: $light-blue;
        text-transform: uppercase;
        font-weight: normal;
        font-size: 1.5rem;
        border-bottom: 3px solid $light-blue;
        margin-bottom: 0;
        z-index: 5;
        position: relative;
    }
    .content-list {
        padding: 0 0 2rem 0;
        margin: -2px 0 3rem 0;
        list-style-type: none;
        border-bottom: 2px solid $light-gray;
        z-index: 1;
        position: relative;

        &.col {
            column-count: 2;
            column-gap: 50px;
        }

        li {
            border-top: 1px solid $light-gray;
            &:last-of-type {
                .article-wrap {
                    border: none;
                }
            }
            .article-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                text-decoration: none;
                padding: 2rem 0;

                .article-title {
                    font-family: $oswald;
                    color: $medium-gray;
                    font-size: 1.3125rem;
                    text-decoration: none;
                    padding: 1rem 1rem 1rem 0;

                    .trimmed {
                        display: none;
                    }
                }
                .article-button {
                    background-color: $red;
                    color: $white;
                    font-family: $oswald;
                    text-transform: uppercase;
                    border-radius: 5px;
                    height: 80px;
                    min-width: 80px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    .icon{
                        svg{
                            width: 16px;
                        }
                    }
                    
                    .tag {
                        font-size: .825rem;
                        white-space: nowrap;
                    }
                }

                &:hover {
                    .article-title {
                        color: $light-charcoal;
                    }
                    .article-button {
                        background-color: $button-red-hover;
                    }
                }
            }
            
        }
    }

    @include breakpoint(tablet, max) {
        .content-list {
            column-count: 1;
            li {
                .article-wrap {
                    .article-button {
                        border-radius: 0px;
                        height: 10vw;
                        width: 10vw;
                        min-width: 10vw;
                        .icon {
                            font-size: 36px;
                        }
                        .tag {
                            display: none;
                        }
                    }
                }  
            }
        }
    }

    @include breakpoint(mobile, max) {
        h3.list {
            font-size: 1.125rem;
        }
        .content-list {
            li {
                .article-wrap {
                    padding: .25rem 0;
                    .article-title {
                        .full {
                            display: none;
                        }
                        .trimmed {
                            display: block;
                        }
                    }
                    .article-button {
                        height: 12vw;
                        width: 12vw;
                        min-width: 12vw;
                        .icon {
                            font-size: 20px;
                        }
                    }
                    .article-title {
                        font-size: 1.125rem;
                        padding-right: 3em;
                    }
                }  
            }
        }
    }
}

section#tips-list {
    h3.feature {
        font-family: $oswald;
        color: $dark-gray;
        font-weight: normal;
        margin-bottom: 4rem;
    }

    .tips {
        display: flex;
        flex-wrap: wrap;

        .tip {
            width: 22%;
            margin-bottom: 4rem;
            &:not(:nth-of-type(4n)) {
                margin-right: 4%;
            }

            &-number {
                font-family: $oswald;
                color: $darker-gray;
                font-size: 30px;
                margin: 1rem 0;
            }

            &-message {
                color: $medium-gray;
                font-family: $merriweather;
                font-size: 17px;
                line-height: 2;
            }

            @include breakpoint(tablet, max) {
                width: 48%;
    
                &:not(:nth-of-type(4n)) {
                    margin-right: 0;
                }
    
                &:not(:nth-of-type(2n)) {
                    margin-right: 4%;
                }
    
            }

            @include breakpoint(mobile, max) {
                width: 100%;
                margin-bottom: 1rem;
    
                &:not(:nth-of-type(4n)) {
                    margin-right: 0;
                }
    
                &:not(:nth-of-type(2n)) {
                    margin-right: 0;
                }
                
                &-number {
                    font-size: 24px;
                }
    
                &-message {
                    font-size: 15px;
                }
            }
        }
    }

    @include breakpoint(mobile, max) {
        h3.feature {
            margin-bottom: 1rem;
        }
    }
}