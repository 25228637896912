section.homepage-block {
    &.charcoal {
        background-color: $light-charcoal;
    }
    &.yellow {
        background-color: $yellow;
    }
    &.white {
        background-color: $white;
    }
    &.blue {
        background-color: $light-blue;
    }

    .homepage-inner {
        padding: 5rem 0 8rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .image-block {
            flex-basis: 50%;
            overflow: visible;

            &.no-caption {
                align-self: stretch;
                .image {
                    height: 100%;
                    .mask {
                        height: 100%;
                    }
                }
            }

            &.right {
                order: 2;

                .image {
                    margin: 0 -0% -5% 5%;

                    .mask {
                        .caption-block {
                            margin-left: 15%;
                        }
                    }
                }
            }

            &.left {
                .image {
                    .mask {
                        .caption-block{
                            margin-left: 30%;
                        }
                    }
                }
            }
            .image-wrap {
                position: relative;
                .bg-mask {
                    position: absolute;
                    top: -20%;
                    width: 130%;
                    height: 140%;
                    left: -20%;
                    z-index: 0;
                }
                .content-img {
                    position: relative;
                    z-index: 5;
                }
            }
            .image {
                background-size: cover;
                background-position: center;
                width: 135%;
                height: 110%;
                margin: 0 -5% -5% -35%;
                .mask {
                    width: 100%;
                    height: 100%;
                    background-size: 100% 100%;

                    &.charcoal {
                        background-image: url('/assets/images/featured-item-mask.png');
                    }
                    &.white {
                        background-image: url('/assets/images/white-mask.png');
                        background-color: rgba(255,255,255,.66);
                    }

                    &.yellow {
                        background-image: url('/assets/images/yellow-mask.png');
                        background-color: rgba(255, 196, 89, 0.66);
                    }

                    &.blue {
                        background-image: url('/assets/images/blue-mask.png');
                        background-color: rgba(102, 150, 242, 0.66);
                    }

                    .caption-block {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        height: 100%;
                        width: 70%;
                        z-index: 10;
                        position: relative;

                        .caption {
                            margin-right: 25%;
                            margin: 6em 20% 0 0;
                        }

                        .link-container {
                            margin: 0 0 6rem 0;
                            .view-link {
                                background-color: $light-charcoal;
                                color: $white;
                                font-family: $oswald;
                                text-transform: uppercase;
                                text-decoration: none;
                                font-size: 14px;
                                padding: .3rem 1rem;
                                border-radius: 5px;
                                display: inline-flex;
                                align-items: center;

                                i {
                                    font-size: 1.75rem;
                                    padding: 0 0 0 1rem;
                                    svg{
                                        width: 24px;
                                        height: auto;
                                    }
                                }

                                &:hover {
                                    background-color: $dark;
                                }

                            }
                        }
                    }

                }
            }
            
        }
        .content-block {
            color: $white;
            flex-basis: 40%;
            position: relative;

            &.list {
                flex-basis: 45%;
            }
            .bg-mask {
                position: absolute;
                top: -20%;
                width: 170%;
                height: 140%;
                left: -20%;
                z-index: 0;
            }
            
            .content-inner {
                position: relative;
                z-index: 5;
                &.featured {
                    
                    h3.feature {
                        margin: 0;
                        color: $charcoal;
                    }
                    .message {
                        font-size: 1.065rem;
                        font-family: $merriweather;
                        line-height: 2;
                        padding: 1em 0;
                        color: $light-charcoal;
                    }

                    &.charcoal {
                        h3.feature, .message {
                            color: $white;
                        }
                    }
                }

                &.single {
                    background-color: $light-charcoal;
                    border-radius: 10px;
                    .event-date {
                        font-family: $oswald;
                        color: $light-charcoal;
                        background-color: $white;
                        display: inline-flex;
                        flex-direction: column;
                        align-items: center;
                        margin: 2rem 0 0 2rem;
                        padding: 0 1.5rem .5rem 1.5rem;
                        align-self: flex-start;

                        .numeral {
                            font-weight: bold;
                            font-size: 4.5rem;
                        }
                        .month {
                            font-size: 1.125rem;
                        }
                    }

                    h3.single {
                        margin: 0;
                        padding: 2rem 2rem .5rem 2rem;
                        font-family: $oswald;
                        font-size: 1.5rem;
                        font-weight: 500;
                    }

                    .message {
                        font-size: .935rem;
                        font-family: $merriweather;
                        line-height: 1.75;
                        padding: 1em 0;
                        padding: .5rem 5rem 2rem 2rem;

                        .mission-list {
                            ul {
                                list-style-type: none;
                                padding: 0;

                                li {
                                    font-size: .825rem;
                                    font-family: $oswald;
                                    font-weight: 300;
                                    line-height: 2.73em;

                                    i {
                                        svg{
                                            width: 16px;
                                            position: relative;
                                            top: 2px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .button {
                        width: 100%;
                        padding: 1rem 0;
                        justify-content: center;
                        text-transform: uppercase;
                        font-size: .875rem;
                        display: flex;
                        align-items: center;
                        
                        i {
                            padding-left: .5rem;
                            font-size: 1.25rem;
                        }
                    }
                }

                &.list {
                    &.white {
                        h3.list {
                            color: $light-blue;
                            padding-bottom: 1rem;
                            border-bottom: 3px solid $light-blue;
                        }
                        .content-list {
                            li {
                                .article-wrap {
                                    .article-title {
                                        color: $medium-gray;
                                    }
                                }
                            }
                        }
                    }
                    
                    h3.list {
                        font-family: $oswald;
                        color: $white;
                        text-transform: uppercase;
                        font-weight: normal;
                        font-size: 1.5rem;
                        border-bottom: 3px solid $white;
                        margin-bottom: 0;
                    }
                    .content-list {
                        padding: 0;
                        margin: 0;
                        list-style-type: none;

                        li {
                            &:last-of-type {
                                .article-wrap {
                                    border: none;
                                }
                            }
                            .article-wrap {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                text-decoration: none;
                                padding: 2rem 0;
                                border-bottom: 1px solid $light-gray;

                                .article-title {
                                    font-family: $oswald;
                                    color: $white;
                                    font-size: 1.3125rem;
                                    text-decoration: none;
                                    padding: 1rem 1rem 1rem 0;

                                    .trimmed {
                                        display: none;
                                    }
                                }
                                .article-button {
                                    background-color: $red;
                                    color: $white;
                                    font-family: $oswald;
                                    text-transform: uppercase;
                                    border-radius: 5px;
                                    height: 80px;
                                    min-width: 80px;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    .icon{
                                        svg{
                                            width: 16px;
                                        }
                                    }
                                    .tag {
                                        font-size: .825rem;
                                        white-space: nowrap;
                                    }
                                }

                                .date-box {
                                    font-family: $oswald;
                                    color: $white;
                                    display: inline-flex;
                                    flex-direction: column;
                                    align-items: center;
                                    padding: 0 1.5rem 0 1.5rem;
                                    align-self: flex-start;
                                    min-width: 15%;
                        
                                    .numeral {
                                        font-weight: bold;
                                        font-size: 3rem;
                                    }
                                    .month {
                                        font-size: .825rem;
                                    }
                                }

                                &:hover {
                                    .article-title {
                                        color: $light-charcoal;
                                    }
                                    .article-button {
                                        background-color: $button-red-hover;
                                    }
                                    .date-box {
                                        color: $light-charcoal;
                                    }
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }

    @include breakpoint(tablet, max) {
        .homepage-inner {
            flex-wrap: wrap;

            .image-block {
                &.left, &.right {
                    order: 0;
                    flex-basis: 100%;
                    
                    &.no-caption {
                        height: 65vw;
                    }

                    .image {
                        margin: 0 0 0 -10%;
                        width: 130%;

                        .mask {
                            .caption-block {
                                width: 75%;
                                margin: 0 0 0 9%;

                                .caption {
                                    margin: 4rem 0 0;

                                    h3.feature {
                                        font-size: 2.25rem;
                                    }
                                }
                                .link-container {
                                    margin: 0 0 4rem;
                                }
                            }
                        }
                    }
                }
            }

            .content-block {
                &.featured {
                    flex-basis: 100%;
                    order: -1;
                }

                &.list {
                    flex-basis: 100%;
                }

                &.single {
                    flex-basis: 100%;
                }
                
                .content-inner {
                    &.featured {
                        padding: 1rem;
                        .message {
                            padding: 0;
                        }
                    }

                    .event-wrapper {
                        display: flex;
                        margin-bottom: 2rem;

                        .block-content {
                            .message {
                               padding-right: 1.5rem; 
                            }
                        }
                    }
                    &.list {
                        .content-list {
                            li {
                                .article-wrap {
                                    .article-button {
                                        border-radius: 0px;
                                        height: 10vw;
                                        width: 10vw;
                                        min-width: 10vw;
                                        .icon {
                                            font-size: 36px;
                                            i{
                                                svg{
                                                    width: 16px;
                                                }
                                            }
                                        }
                                        .tag {
                                            display: none;
                                        }
                                    }
                                }  
                            }
                        }
                    }
                    
                }
                .button {
                    width: 100%;
                    padding: 1rem 0;
                    text-align: center;
                }
            }
        }
    }

    @include breakpoint(mobile, max) {
        .homepage-inner {
            padding: 3rem 0 4rem;
            .image-block {
                &.right, &.left {
                    .image {
                        .mask {
                            .caption-block {
                                .caption {
                                    margin-top: 2rem;
                                    h3.feature {
                                        font-size: 1.25rem;
                                    }
                                }
                                .link-container {
                                    margin-bottom: 2rem;
                                }
                            }
                        }
                    }
                }
            }
            .content-block {
                &.featured {
                    a {
                        margin-bottom: 2rem;
                    }
                }
                .content-inner {
                    &.featured {
                        .message {
                            font-size: 13px;
                            line-height: 1.5;
                        }
                    }

                    &.single {
                        h3.single {
                            font-size: 1.125rem;
                        }
                        .message {
                            font-size: 13px;
                            line-height: 1.5;
                        }
                        .event-wrapper {
                            .event-date {
                                margin: 1.5rem 0 0 1.5rem;
                                .numeral {
                                    font-size: 2.25rem;
                                }
                            }
                        }
                    }

                    &.list {
                        h3.list {
                            font-size: 1.125rem;
                        }
                        .content-list {
                            li {
                                .article-wrap {
                                    padding: .25rem 0;
                                    .article-title {
                                        .full {
                                            display: none;
                                        }
                                        .trimmed {
                                            display: block;
                                        }
                                    }
                                    .article-button {
                                        height: 12vw;
                                        width: 12vw;
                                        min-width: 12vw;
                                        .icon {
                                            font-size: 20px;
                                            i{
                                                svg{
                                                    width: 16px;
                                                }
                                            }
                                        }
                                    }
                                    .article-title {
                                        font-size: 1.125rem;
                                        padding-right: 3em;
                                    }
                                }  
                            }
                        }
                    }
                }
            }
        }
    }
}