div#submit-event {
    display: flex;
    outline: 4px dashed $light-gray;
    align-items: center;
    padding: .5rem 0;
    margin: 3rem auto;

    .plus {
        color: $gray;
        font-size: 100px;
        line-height: .5;
        padding: 1rem 2rem 2rem;
        text-align: center;
    }

    .main {
        .heading {
            font-family: $oswald;
            font-size: 21px;
            font-weight: 600;
            color: $medium-gray;
        }

        .description {
            font-family: $merriweather;
            color: $medium-gray;
            font-size: 14px;
        }
    }

    .button-wrapper {
        margin-left: auto;
        padding: 0 1rem;

        a {
            white-space: nowrap;
        }
    }

    @include breakpoint(mobile, max) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        outline: 0;
        border: 4px dashed $light-gray;
        .main {
            order: -1;
            padding: 0 1rem;

            .heading {
                font-size: 18px;
            }

            .description {
                font-size: 13px;
            }
        }

        .plus {
            font-size: 80px;
            margin-top: -1rem;
            padding: 1rem 0 2rem;
        }
        .button-wrapper {
            margin: 0 auto;
        }
    }
}

section#events-list {
    margin-bottom: 12rem;
    .event {
        display: flex;
        align-items: stretch;
        .date-box {
            font-family: $oswald;
            color: $light-charcoal;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            padding: 0 1.5rem .5rem 1.5rem;
            align-self: flex-start;
            min-width: 15%;

            .numeral {
                font-weight: bold;
                font-size: 4.5rem;
            }
            .month {
                font-size: 1.125rem;
            }
        }

        .main {
            border-bottom: 1px solid $light-gray;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-grow: 1;
            .heading {
                a {
                    font-family: $oswald;
                    font-size: 21px;
                    font-weight: 600;
                    color: $medium-gray;

                    &:hover {
                        color: $black;
                    }
                }
            }
    
            .description {
                font-family: $merriweather;
                color: $medium-gray;
                font-size: 14px;
                padding-right: 1rem;
            }
        }

        .details-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-bottom: 1px solid $light-gray;
            min-width: 20%;
            max-width: 20%;

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                line-height: 2.75;
                font-size: 14px;

                li {
                    display: flex;
                    align-items: center;
                    color: $medium-gray;
                    font-family: $oswald;

                    i {
                        font-size: 20px;
                        padding-right: 1rem;
                    }
                }
            }
        }
    }

    @include breakpoint(tablet, max) {
        .event {
            .details-wrapper {
                min-width: 30%;
                max-width: 30%;
            }
        }
    }

    @include breakpoint(mobile, max) {
        .event {
            flex-wrap: wrap;

            .date-box {
                align-items: center;
                align-self: flex-start;
                min-width: 15%;
                max-width: 15%;
                padding: 0 5% .5rem 0;
    
                .numeral {
                    font-size: 28px;
                }
                .month {
                    font-size: 13px;
                }
            }

            .main {
                min-width: 80%;
                max-width: 80%;
                border: none;

                .heading {
                    font-size: 18px;
                }

                .description {
                    font-size: 13px;
                }
            }

            .details-wrapper {
                min-width: 100%;
                max-width: 100%;
                padding-bottom: 1rem;
                margin-bottom: 1rem;

                ul {
                    padding-left: 30%;

                    li {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}