.text-center {
    text-align: center;
}

.button {
    font-family: $oswald;
    font-weight: 600;
    font-size: 1.125rem;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;

    &.sq {
        padding: .75rem 3rem;
        border-radius: 5px;
    }
    &.secondary {
        padding: .5rem 1.5rem;
        border-radius: 5px;
        font-size: 1rem;
    }
    &.rd {
        background-color: $red;
        color: $white;
        &:hover {
            background-color: $button-red-hover;
        }
    }

    &.bl {
        background-color: $light-blue;
        color: $white;
        &:hover {
            background-color: $hover-blue;
        }
    }
}

.max-width {
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
}
.max-inner {
    width: 90% !important;
    max-width: 1200px;
    margin: 0 auto;
}

h3.feature {
    font-size: 2.25rem;
    font-family: $merriweather;

    @include breakpoint(tablet, max) {
        font-size: 1.75rem;
    }

    @include breakpoint(mobile, max) {
        font-size: 1.125rem;
    }
}

.selectric-wrapper {
    .selectric {
        background-color: $light-charcoal;
        border-color: $light-charcoal;
        .label {
            color: $white;
            font-family: $oswald;
            text-transform: uppercase;
            font-size: 14px;
        }
        .button {
            color: $light-charcoal;
            background-color: $light-charcoal;
            font-size: 14px;

            &:after {
                border-top-color: $white;
            }
        }
    }
    .selectric-items {
        border: none;
        .selectric-scroll {
            ul {
                li {
                    background-color: $lighter-charcoal;
                    color: $white;
                    border: .5px solid $light-charcoal;

                    &:hover {
                        background-color: $charcoal;
                    }

                    &.selected.highlighted {
                        background-color: $medium-charcoal;
                    }
                }
            }
        }
    }
}

.fixed {
    position: fixed;
}

html.fixed {
    width: 100vw;
}

#main-content {
    ul {
        list-style: none !important;
        li::before {
            content: '➞';
            color: $blue-gray;
            margin-left: -20px; 
            margin-right: 10px;
        }
    }
    
    ul.bullets {
        list-style-type: disc;
        li::before {
            content: '';
            margin: 0;
        }
    }
    
    ul.bulletless {
        list-style-type: none;
        li::before {
            content: '';
            margin: 0;
        }
    }

    .page-secondary-text {
		color:#B0B0B0;
		font-family: "Oswald", sans-serif;
		font-weight: 400;
		font-size:  15px;
		line-height: 20px;
		text-transform: uppercase;
		margin:0 0 25px;
		@media screen and (max-width: 768px){
			font-size: 18px;
			line-height: 25px;
			margin:0 0 50px;
		}
		a {
			color:#B0B0B0;
			&:hover, &:focus {
				.no-touchevents & {
					color: #6A6A6A;
				}
			}
		}
	}
	

    .page-block-title {
		color: #8B8B8B;
		font-family: "Oswald", sans-serif;
		font-weight: 400;
		font-size: 24px;
		line-height: 28px;
		margin:0 0 10px;
		@media screen and (max-width: 768px){
			font-size: 30px;
			line-height: 40px;
			margin:0 0 20px;
		}
	}

    .page-sidebar-title {
		color: #FFA400;
		font-family: "Oswald", sans-serif;
		font-weight: 400;
		font-size: 18px;
		line-height: 25px;
		text-transform: uppercase;
		margin:0 0 10px;
		@media screen and (max-width: 768px) {
			margin:0 0 20px;
		}
	}

    .page-secondary-headline {
		color: #343434;
		font-family: "Oswald", sans-serif;
		font-weight: 300;
		font-size: 21px;
		line-height: 27px;
		margin:0 0 10px;
		@media screen and (max-width: 768px){
			font-size: 24px;
			line-height: 34px;
			margin:0 0 20px;
		}
		a {
			color: #343434;
			&:hover, &:focus {
				.no-touchevents & {
					color: #DB423C;
				}
			}
		}
	}

    .blurb {
        font-family: "Merriweather", serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin:0 0 25px;
        @media screen and (max-width: 768px){
          font-size: 16px;
            line-height: 26px;
            margin:0 0 35px;
    }
}
}

