section#featured-resources, section.featured-resources {
    display: flex;
    padding: 2rem 0;
    justify-content: space-between;
    align-items: stretch;
    .featured-resource {
        flex-basis: 31.5%;
        display: flex;
        flex-direction: column;
        .featured-info-block {
            background-color: $light-charcoal;
            color: $white;
            border-radius: 0 0 5px 5px;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            h4 {
                margin: 0;
                padding: 1.5rem;
                font-family: $oswald;
                font-size: 1.5rem;
                font-weight: normal;
            }
            .description {
                padding: 0 1.5rem 1.5rem 1.5rem;
                font-family: $merriweather;
                font-size: 15px;
                line-height: 1.65;
                margin-bottom: auto;
            }

            .button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                padding: 1rem 0;
                font-weight: normal;
                font-size: 14px;
                margin-top: auto;

                i {
                    font-size: 20px;
                    padding-left: 10px;
                    svg{
                        width: 16px;
                    }
                }

            }
        }
    }

    @include breakpoint(tablet, max) {
        flex-wrap: wrap;
        .featured-resource {
            flex-basis: 100%;
            margin-bottom: 1.5rem;
        }
    }

    @include breakpoint(mobile, max) {
        .featured-resource {
            .featured-info-block {
                h4 {
                    font-size: 1.125rem;
                }
                .description {
                    font-size: 13px;
                }
            }
        }
    }
}