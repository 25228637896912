header {
    .toolbar {
        background-color: $light-blue;
        .mobile-menu {
            display: none;
            position: fixed;
            width: 100%;
            height: 100%;
            background-color: $light-blue;
            z-index: 10;
            
            &.open {
                display: block;
            }

            .menu-top {
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid $mediumLight-blue;

                a {
                    display: block;
                    padding: .35em 0 .35em 5%;
                    color: $white;
                    text-decoration: none;
                    font-family: $oswald;
                    font-size: 1.125rem;

                    &:nth-child(odd) {
                        background-color: $mediumLight-blue;
                    }

                    &:hover {
                        color: $soft-blue;
                    }
                }
            }

            .menu-bottom {
                display: flex;
                flex-direction: column;
                padding-top: .75rem;

                a {
                    display: block;
                    padding: .5rem 0 .5rem 5%;
                    color: $white;
                    text-decoration: none;
                    font-family: $libre;
                    font-size: .875rem;

                    i {
                        color: $medium-blue;
                        margin-left: .25rem;
                        svg{
                            width: 16px;
                        }
                    }
                    &:hover {
                        color: $soft-blue;
                        i{
                            color: $gray-blue;
                        }   
                    }
                }

            }
        }
        .toolbar-inner {
            display: flex;
            align-items: center;
            color: $white;
            font-family: $libre;
            font-size: .8125rem;
            padding: 1rem 0;
            
            a {
                color: $white;
                text-decoration: none;
                i{
                    svg{
                        width: 16px;
                    }
                }

                &:hover {
                    color: $soft-blue;
                }
            }

            .spacer {
                margin-right: 2rem;
                padding-right: 2rem;
                border-right: 1px solid $medium-blue;
            }
            
            .tagline {
                font-style: italic;
                margin-right: auto;
                letter-spacing: .5px;

                &.open {
                    display: none;
                }
            }

            .external {
                a {
                    white-space: nowrap;
                    margin-left: 5px;
                    i {
                        color: $medium-blue;
                        margin-left: .25rem;
                        font-size: 11px;
                        svg{
                            width: 16px;
                            height: auto;
                        }
                    }
                    &:hover {
                        i{
                            color: $gray-blue;
                        }   
                    }
                }
            }
            .utility-pages {
                display: flex;
                a {
                    font-weight: bold;
                    margin: 0 1rem;
                    white-space: nowrap;
                }
            }
        }
    }
    .menu-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem;
        .logo-wrapper {
            display: flex;
            align-items: center;

            .wordmark {
                a {
                    font-family: 'Alternate Gothic No2';
                    text-transform: uppercase;
                    font-size: 1.375rem;
                    letter-spacing: .59px;
                    margin-right: 1rem;
                    padding: .5rem 1rem .5rem 0;
                    border-right: 1px solid $gray;
                    color: black;

                    &:hover {
                        color: $light-charcoal;
                    }
                }
            }
            .logo {
                img {
                    height: 45px;
                    width: auto;
                }
                .full {
                    display: block;
                }
                .mobile {
                    display: none;
                }
            }
        }
        .nav-menu {
            .nav-item {
                font-size: 1.125rem;
                color: $black;
                text-decoration: none;
                margin-left: 2.5rem;
                font-family: $oswald;

                &:hover {
                    color: $dark-gray;
                }

                &.active {
                    color: $red;
                }

                @include breakpoint(desktop, max) {
                    margin-left: 1rem;
                }
            }
        }

        .search-wrap-mobile {
            display: none;
            
            #search-trigger-mobile {
                i {
                    color: $dark-gray;
                }
            }
        }
    }

    @include breakpoint(tablet, max) {
        .toolbar {
            .toolbar-inner {
                .external, .utility-pages, .search-wrap {
                    display: none;
                }
            }
        }
        .menu-bar {
            .logo-wrapper {
                .logo {
                    .full {
                        display: none;
                    }
                    .mobile {
                        display: block;
                    }
                }
            }
            .nav-menu {
                display: none;
            }

            .search-wrap-mobile {
                display: block;
            }
        }
    }

    @include breakpoint(mobile, max) {
        .menu-bar {
            padding: 1rem 0;
        }
    }
}