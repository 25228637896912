section#serve-content {
    margin-bottom: 5rem;
    .select-row {
        display: flex;
        padding: 3rem 0 2rem;
        justify-content: space-between;
        .selectric-wrapper {
            width: 31.5%;
        }
    }

    .serve-grid {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.375%;
        width: 102.75%;
        .serve-item, .serve-contact {
            width: 30.65%;
            margin: 0 1.325% 3rem;

            h4, .description, .mission-list {
                margin: 1.5rem;
                color: $medium-gray;
            }
            h4 {
                font-family: $oswald;
                font-size: 21px;
                font-weight: 600;
            }
            .description {
                font-family: $merriweather;
                font-size: 12px;
                line-height: 2;
            }
        }
        .serve-contact {
            outline: 4px dashed $light-gray;

            .plus {
                color: $gray;
                font-size: 180px;
                line-height: .5;
                padding: 1rem 0 4rem;
                text-align: center;
            }

            .button-wrap {
                display: flex;
                justify-content: center;
            }

        }
        .serve-item {
            background-color: $ultra-light-gray;
            border-radius: 0 0 5px 5px;
            display: flex;
            flex-direction: column;

            .mission-list {
                font-family: $oswald;
                margin-top: auto;
                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    line-height: 2.75;
                    font-size: 14px;

                    li {
                        display: flex;

                        i {
                            padding-right: 1rem;
                            padding-top: .15rem;
                            svg{
                                width: 16px;
                            }
                        }
                    }
                }
            }

            .button {
                width: 100%;
                padding: 1.25rem 0;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 400;
                text-transform: uppercase;

                i {
                    font-size: 20px;
                    padding-left: .5rem;
                    position: relative;
                    top: 1px;
                    svg{
                        width: 16px;
                    }
                }
            }
        }
    }

    @include breakpoint(tablet, max) {
        .select-row {
            flex-wrap: wrap;

            .selectric-wrapper {
                width: 100%;
                margin-bottom: 1rem;
            }
        }

        .serve-grid {
            display: flex;
            flex-wrap: wrap;
            margin-left: -2.25%;
            width: 104.5%;
            .serve-item, .serve-contact {
                width: 46%;
                margin: 0 2% 3rem;
            }
        }
    }

    @include breakpoint(mobile, max) {
        .serve-grid {
            display: flex;
            flex-wrap: wrap;
            margin-left: 0;
            width: 100%;
            .serve-contact {
                outline: 0;
                border: 4px dashed $light-gray;

                .plus {
                    font-size: 80px;
                    margin-top: -2rem;
                    padding: 1rem 0 2rem;
                }
            }
            .serve-item, .serve-contact {
                width: 100%;
                margin: 0 0 3rem;

                h4 {
                    font-size: 18px;
                }

                .mission-list {
                    ul {
                        display: flex;
                        justify-content: space-around;
                        li {
                            font-size: 12px;

                            i {
                                font-size: 14px;
                                padding-right: .5rem;
                            }
                        }
                    }
                }

                .button-wrap {
                    padding-bottom: 2rem;
                }
            }
        }
    }
}