section#tag-results {
    border-top: 1px solid $light-gray;
    .tag-inner {
        h1.feature {
            margin-bottom: 0;
            font-size: 3.625rem;
            font-family: $merriweather;
            color: $light-charcoal;
        }
        .query {
            font-family: $oswald;
            color: $hover-gray;
            font-size: 1.875rem;
            margin-top: 0;
            font-weight: 400;
        }

        @include breakpoint(tablet, max) {
            h1.feature {
                font-size: 2.75rem;
            }
            .query {
                font-size: 1.25rem;
            }
        }

        @include breakpoint(mobile, max) {
            h1.feature {
                font-size: 2rem;
            }
            .query {
                font-size: 1rem;
            }
        }
    }   
}