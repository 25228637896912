.speaker-heading {
    display: flex;
    justify-content: space-between;
    img {
        border-radius: 100%;
        width: 25%;
    }

    @include breakpoint(mobile, max) {
        flex-wrap: wrap;
        align-items: flex-start;
        img {
            width: 100px;
            height: auto;
        }
    }
}
.speaker-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;

    .speaker {
        width: calc((100% - 4rem) / 3);
        background-color: $ultra-light-gray;
        border-radius: 5px;
        position: relative;
        margin-top: 25px;
        display: flex;
        flex-direction: column;

        .portrait {
            position: absolute;
            right: 10px;
            transform: translateY(-33%);
            transition: transform .5s ease;

            img, .user {
                height: 75px;
                width: auto;
                border-radius: 100%;
            }

            .user {
                aspect-ratio: 1 / 1;
                background-color: $extra-light-gray;
                border-radius: 100%;
            }
            svg {
                position: absolute;
                fill: $white;
                top: 50%;
                left: 50%;
                height: 40%;
                transform: translate(-50%, -50%);
            }
        }

        .details {
            padding: 1rem;

            .name {
                color: $medium-gray;
                font-size: 1.25rem;
                margin: 0 0 1rem;
                font-family: $oswald;
                max-width: calc(100% - 90px);
            }

            .summary {
                color: $medium-gray;
                font-family: $libre;
                font-size: .825rem;
            }
        }

        .read {
            background-color: $red;
            border-radius: 0 0 5px 5px;
            text-align: center;
            padding: 1rem;
            font-family: $oswald;
            color: $white;
            margin-top: auto;
        }

        &:hover {
            background-color: $extra-light-gray;

            .portrait {
                transform: translateY(-50%);

                .user {
                    background-color: $tags;
                }
            }

            .read {
                background-color: $dark-red;
            }
        }
    }

    @include breakpoint(desktop, max) {
        .speaker {
            width: calc((100% - 2rem) / 2);
        }
    }

    @include breakpoint(tablet, max) {
        .speaker {
            width: calc((100% - 4rem) / 3);
        }
    }

    @include breakpoint(mobile, max) {
        .speaker {
            width: calc((100% - 2rem) / 2);
        }
    }

    @include breakpoint(xs, max) {
        .speaker {
            width: 100%;
        }
    }
}