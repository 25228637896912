footer {
    .footer-wrap {
        .footer-top {
            background-color: $charcoal;
            .footer-inner {
                padding: 4em 0;
                
                .feature-links-col {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap:  1.5rem;
                    .footer-block {
                        padding-bottom: 2rem;
                        h3 {
                            font-family: $merriweather;
                            font-size: 1.875rem;
                            color: $white;
                            font-weight: 900;
                            margin: 0 0 .75rem;
                            a {
                                font-family: $merriweather;
                                font-size: 1.875rem;
                                color: $white;
                                text-decoration: none;
                                font-weight: 900;
                                transition: color .2s ease;

                                &:hover {
                                    color: $hover-gray;
                                }
                            }
                            
                        }
                        .message {
                            font-family: $merriweather;
                            color: $text-gray;
                            width: 75%;
                            font-style: italic;
                            font-size: .75rem;
                        }

                    }
                }

                .network-links-col {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    gap: 2.7rem;
                    .footer-block {
                        margin-left:  3em;
                        h3 {
                            font-family: $oswald;
                            font-size: 1.125rem;
                            color: $yellow;
                            font-weight: 400;
                            margin: 0;
                            a {
                                font-family: $oswald;
                                font-size: 1.125rem;
                                color: $yellow;
                                text-decoration: none;
                                font-weight: 400;
                                transition: color .2s ease;
                            }

                            a[href] {
                                &:hover{
                                    color: $hover-yellow;
                                } 
                            }
                        }
                        .message {
                            font-family: $merriweather;
                            color: $text-gray;
                            width: 75%;
                            font-style: italic;
                            font-size: .75rem;
                        }
                        .footer-social-links {
                            margin-top: 1em;
                            a {
                                i {
                                    transition: all .3s ease;
                                }
                                &:hover {
                                    i {
                                        color: $black;
                                        &.yt,&.fb, &.tw, &.ig {
                                            background-color: $white;
                                        }
                                    }
                                }
                                
                                i{
                                    display: inline-block;
                                    color: $white;
                                    font-size: 1.5em;
                                    border-radius: 100%;
                                    margin-right: .5rem;
                                    padding: .35rem;
                                    height: 34px;
                                    width: 34px;
                                    position: relative;

                                    &:before {
                                        position: absolute;
                                        top: 50%;
                                        right: 50%;
                                    }
                                    
    
                                    &.fb {
                                        background-color: $fb;
                                        &:before {
                                            margin: -9px -8px 0 0;
                                        }
                                    }
                                    &.tw {
                                        background-color: $tw;
                                        &:before {
                                            margin: -9px -8px 0 0;
                                        }
                                    }
                                    &.yt {
                                        background-color: $yt;
                                        &:before {
                                            margin: -9.25px -9.5px 0 0;
                                        }
                                    }
                                    &.ig {
                                        background-color: $ig;
                                        &:before {
                                            margin: -9px -8px 0 0;
                                        }
                                    }
                                }
                                
                            }
                        }
                    }
                }

                .form-col {
                    h3 {
                        font-family: $merriweather;
                        font-size: 1.875rem;
                        color: $white;
                        font-weight: 900;
                        margin: 0;
                        a {
                            font-family: $libre;
                            font-size: 1.875rem;
                            color: $white;
                            text-decoration: none;
                            font-weight: 900;
                        }
                        
                    }
                    .message {
                        font-family: $merriweather;
                        color: $text-gray;
                        width: 75%;
                        font-style: italic;
                        font-size: .75rem;
                    }
                }

                @include breakpoint(tablet, max) {
                    .feature-links-col {
                        flex-basis: 50%;
                    }
                    .network-links-col {
                        flex-basis: 50%;
                    }
                    .form-col {
                        flex-basis: 75%;
                        margin-top: 4em;
                    }
                }

                @include breakpoint(mobile, max) {
                    .feature-links-col {
                        flex-basis: 100%;
                        gap: unset;
                        .footer-block {
                            h3 {
                                font-size: 1.125rem;
                                a {
                                    font-size: 1.125rem;
                                }
                            }
                            .message {
                                width: 100%;
                            }
                        }
                    }
                    .network-links-col {
                        flex-basis: 100%;
                        justify-content: space-between;
                        flex-direction: row;
                        flex-wrap: wrap;
                        gap: unset;
                        .footer-block {
                            
                            margin: 1rem 0;
                            h3 {
                                font-size: .8rem;
                                a {
                                    font-size: .8rem;
                                }
                            }

                            &:nth-child(odd) {
                                flex-basis: 35%;
                            }

                            &:nth-child(even) {
                                flex-basis: 65%;
                            }

                            .footer-social-links {
                                a {
                                    i {
                                        margin-right: .25rem;
                                    }
                                }
                            }

                            &.block-social {
                                flex-basis: 100%;
                            }
                        }
                    }
                    .form-col {
                        flex-basis: 100%;
                        margin-top: 4em;

                        button {
                            width: 100%;
                        }
                        input {
                            width: 90%;
                            padding: .75rem 5%;
                        }
                    }
                }
            }
        
        }
    
        .footer-bottom {
            .footer-inner {
                width: 90%;
                max-width: 1200px;
                margin: 1.5rem auto;
                align-items: center;
                .fb-left {
                    text-align: right;
                    color: $medium-gray;
                    font-size: 12px;
                    font-family: $libre;
                    line-height: 24px;
                    
                    .footer-links {
                        padding-top: 2em;
                    }
                    .footer-links>a {
                        font-size: 12px;
                        font-family: $libre;
                        line-height: 24px;
                        text-decoration: none;
                        padding-left: 2em;
                        transition: color .2s ease;
    
                        &:hover {
                            color: $light-gray;
                        }
                    }
                }
                .fb-right {
                    img {
                        max-width: 292px;
                    }
                }

                @include breakpoint(tablet, max) {
                    .fb-left {
                        flex-basis: 100%;
                        margin-top: 2rem;
                        text-align: center;
                    }
                    .fb-right {
                        flex-basis: 100%;
                        display: flex;
                        justify-content: center;
                        img {
                            width: 80%;
                        }
                    }
                }

                @include breakpoint(mobile, max) {
                    .fb-left {
                        flex-basis: 100%;
                        margin-top: 2rem;
                        text-align: center;
                        font-size: 10px;
                        
                    }
                    .fb-right {
                        flex-basis: 100%;

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}